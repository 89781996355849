import router from "../router";
import store from "@/store";
import { constant } from "@/store/constant";

router.beforeEach(async (to, from, next) => {
  const user = store.getters["user/getUser"] || {};
  if (from.query.plan && to.path === "/setuppricing") {
    if (to.path === "/setuppricing" && to.query.plan === from.query.plan) {
      return next();
    } else {
      return next({ path: "/setuppricing", query: { plan: from.query.plan } });
    }
  } else if (to.path === "/setuppricing" && user && user.pricingPlan) {
    return next("/loops/create");
  } else {
    return next();
  }
});
